import React, {Component} from 'react';
import cx from 'classnames';
import {Drawer} from 'antd';


const defDrawerProps ={
    closable:true,
    destroyOnClose:true,
    //getContainer:null,
    maskClosable:false,
    mask:true,
    //maskStyle:null,
    //style:null,
    //drawerStyle:null,
    //headerStyle:null,
    //bodyStyle:null,
    title:'',
    width:255,
    height:255,
    placement:'right',
    className:'',
    zIndex:1000,
    // onClose:null,
    // afterVisibleChange:null,
    keyboard:false
}
class Module extends Component {
    constructor(props) {
        super(props);
        this.state = this.getInitState(props);
        this.open =this.open.bind(this);
        this.close =this.close.bind(this);
    }
    getInitState(props) {
        let state = {
            module:null,
            drawerProps: {},
            visible:false,
        };

        return state;
    }

    componentWillReceiveProps(props) {
    }

    componentDidMount() {
    }

    componentWillUnmount() {
    }
    open(module,onClose,drawerProps){
        let self=this;
        let _props = Object.assign({},defDrawerProps,drawerProps);
        _props.onClose = ()=>{
            if('function' !== typeof onClose || onClose(self)!==false){
                self.close();
            }
        }
        let d = {
            module,
            drawerProps:_props,
            visible:true
        };
        this.setState(d);
    }
    close(clear){
        this.setState((state)=>{
            return {
                module:clear?null:state.module,
                drawerProps:clear?{}:state.drawerProps,
                visible:false
            }
        });
    }
    render() {
        let self = this;
        let state = self.state;
        let {module,drawerProps,visible} = state;
        return (
            <Drawer
                {...drawerProps}
                visible={module && visible}
            >
                {module}
            </Drawer>
        )
    }
}

export default Module
