import Service from '@core/Service';
import MemeryStorage from '@core/MemeryStorage';
const displayName = 'AppService';
class ModuleService extends Service{
    constructor(api, env, root){
        super(api);
        let self=this;
        const _storage = new MemeryStorage();
        self.events={};
        self.smsOrders={
            gs_cap_code:'',
            gs_sms_order_submited:false,
            gs_sms_sended:false,
            gs_sms_code:null,
            gs_sms_order:null,
            gs_sms_success:null
        };
        self.storage = function(key,value){
            if(value === undefined){
                return _storage.getItem(key);
            }else if(value === '') {
                _storage.removeItem(key);
            }else{
                _storage.setItem(key,value);
            }
        };
        self.sms={
            receiveSMSOrder:(msgObj,callback)=>{
                // console.log(`receiveSMSOrder ${JSON.stringify(msgObj)}`);
                // {"key":"gs_sms_code","data":"985722","time":1656658144011,"mobile":"1065097601286"}
                if(msgObj.key && msgObj.time){
                    let flag=false;
                    if(self.smsOrders[msgObj.key] && self.smsOrders[msgObj.key].time){
                        if(msgObj.time - self.smsOrders[msgObj.key].time>=500){
                            flag=true;
                        }
                    }else{
                        flag=true;
                    }
                    if(flag){
                        self.smsOrders[msgObj.key]=msgObj;
                        // console.log(`dispatch sms_order_receive ${JSON.stringify(msgObj)}`);
                        self.dispatchEvent('sms_order_receive',msgObj);
                    }
                }
            }
        };
        self.log={
            create:(param,callback)=>{
                self.api.analyse.log.create(param,callback);
            },
            todayCount:(param,callback)=>{
                self.api.analyse.log.todayCount(param,callback);
            }
        };
        self.version={
            upgrade:{
                check:(param,callback)=>{
                    self.api.analyse.version.upgrade.check(param,callback);
                },
                create:(param,callback)=>{
                    self.api.analyse.version.upgrade.create(param,callback);
                }
            }
        };
        self.activity={
            checkCurrentActivity:(param,callback)=>{
                self.api.audioroom.activity.activity.checkCurrentActivity(param,callback);
            }
        };
        self.sale={
            transit:{
                search:(param,callback)=>{
                    self.api.sale.transit.search(param,callback);
                }
            }
        };
        self.imei={
            encode:(param,callback)=>{
                self.api.imei.encode(param,callback);
            },
            primeGainGzh:(param,callback)=>{
                self.api.imei.gzh.primeGain(param,callback);
            },
            shouldSendTemplateMessage: (param,callback)=>{
                self.api.audioroom.weixin.device.shouldSendTemplateMessage(param,callback);
            },
            checkByImei:(param,callback)=>{
                self.api.audioroom.weixin.device.checkByImei(param,callback);
            },
            getImeiRegist: (param,callback)=>{
                self.api.imei.stcard.getImeiRegist(param,callback);
            },
            getImeiEncRegist: (param,callback)=>{
                self.api.imei.stcard.getImeiEncRegist(param,callback);
            },
            init: (param,callback)=>{
                self.api.imei.stcard.init(param,callback);
            },
            info:(param,callback)=>{
                let channelKey= root.APP_ENV.channelKey;
                switch (channelKey) {
                    case 'cmhe-gzh':
                        self.api.imei.gzh.info(param,callback);
                        break;
                    case 'cmhe-gsu':
                    default:
                        self.api.imei.info(param,callback);
                }
            },
            check:(param,callback)=>{
                let channelKey= root.APP_ENV.channelKey;
                let {category,imei,_id} = root.profile.imei;
                switch (channelKey) {
                    case 'cmhe-gzh':
                        self.api.imei.gzh.check({imei:imei, id:_id},callback);
                        break;
                    case 'cmhe-gsu':
                        self.api.imei.gsu.check(param,callback);
                    default:
                        switch (category){
                            case 'cmhe-gsu':
                            case 'imei':
                                self.api.imei.gsu.check(param,callback);
                                break;
                            case 'cmhe-gzh':
                                self.api.imei.gzh.check({imei:imei, id:_id},callback);
                                break;
                            default:
                                self.api.imei.stcard.check({
                                    imei:imei,
                                    id:_id
                                },callback);
                        }
                        break;
                }
            },
            countLimit:(param,callback)=>{
                self.api.imei.countLimit(param,callback);
            },
            heartbeat:(param,callback)=>{
                self.api.imei.heartbeat(param,callback);
            }
        };
        self.setting={
            schoolContext:{
                findOne:(param,callback)=>{
                    self.api.audioroom.setting.schoolContext.findOne(param,callback);
                }
            },
            locktime:{
                nowInLock:(param,callback)=>{
                    self.api.audioroom.setting.locktime.nowInLock(param,callback);
                }
            }
        };
        self.middleware={
            wear:{
                cos:{
                    save:(param,callback)=>{
                        self.api.middleware.wear.cos.save(param,callback);
                    },
                    cancel:(param,callback)=>{
                        self.api.middleware.wear.cos.cancel(param,callback);
                    }
                }
            },
            proxy:{
                edugansu:{
                    sendcodeForQrcode:(param,callback)=>{
                        self.api.middleware.proxy.edugansu.sendcodeForQrcode(param,callback);
                    },
                    checkPhone:(param,callback)=>{
                        self.api.middleware.proxy.edugansu.checkPhone(param,callback);
                    },
                    receiveMobileCodeByPhoneNo:(param,callback)=>{
                        self.api.middleware.proxy.edugansu.receiveMobileCodeByPhoneNo(param,callback);
                    },
                    unifiedPayByPhone:(param,callback)=>{
                        self.api.middleware.proxy.edugansu.unifiedPayByPhone(param,callback);
                    },
                }
            }
        };

        self.voice={
            health:(param,callback)=>{
                self.api.voice.health(param,callback);
            },
            record:{
                saveKouyuRecord: (param,callback)=>{
                    self.api.voice.record.saveKouyuRecord(param,callback);
                }
            }
        };
        self.point={
            shouldAddPoint:(param,callback)=>{
                self.api.audioroom.point.point.shouldAddPoint(param,callback);
            },
        };
        self.netStorage = {
            get: (name,callback) =>{
                self.api.storage.get({name},callback);
            },
            set: (name,data,callback)  =>{
                self.api.storage.set({name,data},callback);
            },
            delete: (name,callback)  =>{
                self.api.storage.delete({name},callback);
            }
        };
    }
}
ModuleService.displayName=displayName;
export default ModuleService;